// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

// Utility to check if the user is authenticated
const isAuthenticated = () => {
    const authToken = localStorage.getItem("token");
    const username = localStorage.getItem("username"); // Check for stored username as well
    return !!authToken && !!username; // Ensure both authToken and username exist
};

// PrivateRoute component
const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
