import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";
import MainLayout from "../components/MainLayout";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import "./Spinner.css"; // Import the CSS for spinner

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PriceDeviationHistogramInternal = () => {
    const { logout } = useAuth();
    const [priceDeviations, setPriceDeviations] = useState([]);
    const [binCompanies, setBinCompanies] = useState({});
    const [latestSnapshotName, setLatestSnapshotName] = useState("");
    const [historicData, setHistoricData] = useState([]);
    const [showHistoricData, setShowHistoricData] = useState(false);
    const [loadingHistoric, setLoadingHistoric] = useState(false); // Loading state for historic data
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(""); // State for storing the selected date
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/home");
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleCheckTokenValidity = async () => {
        setLoading(true);
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const backendEndpoint = `${API_BASE_URL}/api/check_token_validity`;

        try {
            const response = await axios.get(backendEndpoint);
            const isTokenValid = response.data.is_access_token_in_s3_valid;
            alert(isTokenValid ? "The access token is valid." : "The access token is not valid.");
        } catch (error) {
            console.error("Error checking token validity:", error);
            alert("An error occurred while checking token validity.");
        } finally {
            setLoading(false);
        }
    };

    const handleRefreshAccessToken = async () => {
        const requestToken = prompt("Please enter your request token:");
        if (!requestToken) {
            alert("Request token is required to refresh access token.");
            return;
        }
        setLoading(true);
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const backendEndpoint = `${API_BASE_URL}/api/refresh_access_token`;

        try {
            const response = await axios.post(backendEndpoint, { request_token: requestToken });
            alert(response.data.access_token ? "Access token refreshed successfully." : "Failed to refresh access token.");
        } catch (error) {
            console.error("Error refreshing access token:", error);
            alert("An error occurred while refreshing access token.");
        } finally {
            setLoading(false);
        }
    };

    const loadHistoricData = async () => {
        if (!selectedDate) {
            alert("Please select a date.");
            return;
        }

        setLoadingHistoric(true); // Start loading
        console.log("Fetching Historic Histograms...");
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const backendEndpoint = `${API_BASE_URL}/api/historic_price_deviations`;

        try {
            const response = await axios.post(backendEndpoint, { date: selectedDate });
            setHistoricData(response.data);
            setShowHistoricData(true);
        } catch (error) {
            console.error("Error fetching historic data:", error);
        } finally {
            setLoadingHistoric(false); // End loading
        }
        console.log("Successfully fetched historic histograms from API");
    };

    useEffect(() => {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const backendEndpoint = `${API_BASE_URL}/api/last_price_deviations`;
        axios.get(backendEndpoint)
            .then((response) => {
                setLatestSnapshotName(response.data.latest_snapshot_name);
                const deviations = response.data.data.map((item) => item.price_deviation);
                const companies = response.data.data.map((item) => item.instrument_name);

                const { binData, binCompanies } = getDeviationBins(deviations, companies);
                setPriceDeviations(binData);
                setBinCompanies(binCompanies);
            })
            .catch((error) => {
                console.error("Error fetching price deviations:", error);
            });
    }, []);

    const getDeviationBins = (deviations, companies) => {
        const bins = {
            "<-10%": { count: 0, companies: [] },
            "-10% to -5%": { count: 0, companies: [] },
            "-5% to -2%": { count: 0, companies: [] },
            "-2% to 0%": { count: 0, companies: [] },
            "0% to 2%": { count: 0, companies: [] },
            "2% to 5%": { count: 0, companies: [] },
            "5% to 10%": { count: 0, companies: [] },
            "> 10%": { count: 0, companies: [] },
        };

        deviations.forEach((deviation, index) => {
            const companyName = companies[index];
            if (deviation < -0.1) {
                bins["<-10%"].count++;
                bins["<-10%"].companies.push(companyName);
            } else if (deviation < -0.05) {
                bins["-10% to -5%"].count++;
                bins["-10% to -5%"].companies.push(companyName);
            } else if (deviation < -0.02) {
                bins["-5% to -2%"].count++;
                bins["-5% to -2%"].companies.push(companyName);
            } else if (deviation < 0) {
                bins["-2% to 0%"].count++;
                bins["-2% to 0%"].companies.push(companyName);
            } else if (deviation < 0.02) {
                bins["0% to 2%"].count++;
                bins["0% to 2%"].companies.push(companyName);
            } else if (deviation < 0.05) {
                bins["2% to 5%"].count++;
                bins["2% to 5%"].companies.push(companyName);
            } else if (deviation < 0.1) {
                bins["5% to 10%"].count++;
                bins["5% to 10%"].companies.push(companyName);
            } else {
                bins["> 10%"].count++;
                bins["> 10%"].companies.push(companyName);
            }
        });

        const totalItems = deviations.length;
        const binData = Object.keys(bins).map((key) => ({
            count: bins[key].count,
            percentage: ((bins[key].count / totalItems) * 100).toFixed(2),
        }));
        const binCompanies = Object.keys(bins).reduce((acc, key) => {
            acc[key] = bins[key].companies;
            return acc;
        }, {});

        return { binData, binCompanies };
    };

    const labels = [
        "<-10%",
        "-10% to -5%",
        "-5% to -2%",
        "-2% to 0%",
        "0% to 2%",
        "2% to 5%",
        "5% to 10%",
        "> 10%",
    ];

    const data = {
        labels,
        datasets: [
            {
                label: "Number of Instruments",
                data: priceDeviations.map((bin) => bin.count),
                backgroundColor: [
                    "#8B0000", "#B22222", "#DC143C", "#FF6347", "#90EE90", "#66CDAA", "#32CD32", "#006400"
                ],
                borderColor: "#333",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Percentage Change Bins",
                    font: { size: 16 },
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Number of Companies",
                    font: { size: 16 },
                },
                beginAtZero: true,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const binLabel = context.label;
                        const count = context.raw;
                        const percentage = priceDeviations[context.dataIndex].percentage;
                        const companiesInBin = binCompanies[binLabel] || [];
                        const companyList = companiesInBin.slice(0, 5).join(", ");
                        return [
                            `Count: ${count} instruments (${percentage}%)`,
                            `Companies: ${companyList}${companiesInBin.length > 5 ? ", ..." : ""}`,
                        ];
                    },
                },
            },
        },
    };

    return (
        <MainLayout onLogout={handleLogout}>
            <div className={`sidebar ${isSidebarOpen ? "open" : "collapsed"}`}>
                <button onClick={toggleSidebar} className="toggle-button">
                    {isSidebarOpen ? "<" : ">"}
                </button>
                {isSidebarOpen && (
                    <div>
                        <h2>Options</h2>
                        <ul>
                            <li onClick={handleCheckTokenValidity}>Check Token Validity</li>
                            <li onClick={handleRefreshAccessToken}>Refresh Access Token</li>
                        </ul>
                    </div>
                )}
            </div>

            <div style={{ width: "90%", margin: "auto" }}>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Price Deviation Histogram</h2>
                {latestSnapshotName && (
                    <p style={{ textAlign: "center", color: "#555" }}>Snapshot: {latestSnapshotName}</p>
                )}
                {priceDeviations.length > 0 ? (
                    <div style={{ height: "700px" }}>
                        <Bar data={data} options={options} />
                    </div>
                ) : (
                    <p>Loading data...</p>
                )}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        style={{ marginRight: "10px" }}
                    />
                    <button
                        onClick={loadHistoricData}
                        style={{
                            marginRight: "10px",
                            padding: "10px 20px",
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            border: "none",
                            cursor: "pointer",
                        }}
                    >
                        Load Historic Data
                    </button>
                    {loadingHistoric && (
                        <div className="spinner"></div>
                    )}
                </div>

                {showHistoricData &&
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                        {historicData.map((historicItem, index) => {
                            const deviations = historicItem.data.map((item) => item.price_deviation);
                            const companies = historicItem.data.map((item) => item.instrument_name);

                            const { binData } = getDeviationBins(deviations, companies);

                            const historicChartData = {
                                labels,
                                datasets: [
                                    {
                                        label: `Snapshot: ${historicItem.snapshot_name}`,
                                        data: binData.map((bin) => bin.count),
                                        backgroundColor: [
                                            "#8B0000", "#B22222", "#DC143C", "#FF6347", "#90EE90", "#66CDAA", "#32CD32", "#006400"
                                        ],
                                        borderColor: "#333",
                                        borderWidth: 1,
                                    },
                                ],
                            };

                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: "23%",
                                        margin: "20px 0",
                                        height: "300px",
                                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                        backgroundColor: "#fff"
                                    }}
                                >
                                    <Bar data={historicChartData} options={options} />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        </MainLayout>
    );
};

export default PriceDeviationHistogramInternal;
